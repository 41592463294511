import styled from 'styled-components'
import { Box } from 'packages/uikit'

const Card = styled(Box)<{
  width?: string
  border?: string
  borderRadius?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  border: ${({ border }) => border};
  background-color: ${({ theme }) => theme.colors.background};
`
export default Card

export const LightCard = styled(Card)`
  border-radius: 8px;
  background-color: var(--modal-bg-color);
`

export const LightGreyCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: ${({ theme }) => theme.colors.background};
`

export const GreyCard = styled(Card)`
  background-color: var(--primary-color);
`

Card.defaultProps = {
  padding: '35px 10px',
}

LightCard.defaultProps = {
  padding: '35px 10px',
}
