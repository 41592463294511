import _toNumber from 'lodash/toNumber'
import _isNaN from 'lodash/isNaN'

export function formatNumber(number: number | string, decimal = 2) {
  const finalNumer = _isNaN(number) || _isNaN(_toNumber(number)) ? 0 : _toNumber(number)

  return finalNumer.toLocaleString('en-US', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  })
}
