import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 16" {...props}>
      <path
        d="M0 3.2H7.00229H18.4554V0H0V3.2ZM0 9.6H18.4554V6.4H0V9.6ZM0 16H18.4554V12.8H0V16ZM21.7277 16H25V12.8H21.7277V16ZM21.7277 0V3.2H25V0H21.7277ZM21.7277 9.6H25V6.4H21.7277V9.6Z"
        fill="#FCB0B5"
      />
    </Svg>
  )
}

export default Icon
